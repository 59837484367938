















































import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import Menu from '@/components/Menu.vue';
import ProjectSelector from '@/components/Project/ProjectSelector.vue';
import FormTitle from '@/components/Common/FormTitle.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { Navigation } from '@/models/Document/DocumentNavigation';
import ConnectivityIndicator from '@/components/Common/ConnectivityIndicator.vue';
import PicturePanelToggler from '@/components/Common/PicturePanelToggler.vue';
import Bowser from 'bowser';
import DocumentLayouts from './models/DocumentLayouts';
export default Vue.extend({
  name: 'App',
  data: () => ({
    toolbarIcon: 'menu',
    navigation: {} as Navigation,
    drawer: false,
    refreshing: false,
    registration: null,
    snackBtnText: '',
    snackWithBtnText: '',
    snackWithButtons: false,
    timeout: 0,
    pendingUpdate: false,
    vh: 100,
    top: 0,
    appbarTop: 20,
    appHeight: 0,
    viewportHeight: 0,
    isPicturePanelClosed: false
  }),

  components: {
    Menu,
    ProjectSelector,
    FormTitle,
    HelpTooltip,
    ConnectivityIndicator,
    PicturePanelToggler
  },

  created() {
    if (navigator.serviceWorker) {
      document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload(true);
      });
    } else {
      this.snackWithBtnText = 'Offline mode not available.';
      this.snackWithButtons = true;
    }
  },
  mounted() {
    const viewport = (window as any).visualViewport;
    this.vh = viewport.height * 0.01;
    this.viewportHandler();
    viewport.addEventListener('scroll', this.viewportHandler);
    viewport.addEventListener('resize', this.viewportHandler);
  },

  watch: {
    $route(to, from) {
      if (to.meta.level > 0) {
        this.toolbarIcon = 'arrow_back';
        this.drawer = false;
      } else {
        this.toolbarIcon = 'menu';
      }
      if (to.name === 'Document') {
        const currentId = this.$route.params.documentId;
        this.navigation = this.getDocumentNavigation(currentId);
      }
    }
  },

  computed: {
    ...mapState({
      userisAuthed: (state: any) => state.userProfile.isAuthed,
      userSettings: (state: any) => state.userProfile.settings
    }),
    ...mapGetters({
      getDocumentNavigation: 'documents/navigation'
    }),
    isSafari() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      const name = browser.getBrowserName();
      const isSafari = name === 'Safari' ? true : false;
      return isSafari;
    },
    logo(): string {
      return `${process.env.BASE_URL}img/logo-mq.png`;
    },

    showLeftNavigation(): boolean {
      if (this.$route.name === 'Document' && this.navigation && this.navigation.previous) {
        return true;
      }
      return false;
    },

    showRightNavigation(): boolean {
      if (this.$route.name === 'Document' && this.navigation && this.navigation.next) {
        return true;
      }

      return false;
    },
    showForwardNavigation() {
      if (this.$route.name === 'Picture' && this.$route.params.documentId) {
        return true;
      }
      return false;
    }
  },

  methods: {
    ...mapActions({
      toggleDocumentPicturePanel: 'userProfile/toggleDocumentPicturePanel',
      closeDocumentPicturePanel: 'userProfile/closeDocumentPicturePanel'
    }),
    closePicturePanel() {
      if (!this.isPicturePanelClosed) {
        this.closeDocumentPicturePanel();
        this.viewportHandler();
      }
      this.isPicturePanelClosed = true;
    },
    handleTogglePicturePanel() {
      window.scrollTo(0, 0);
      this.toggleDocumentPicturePanel();
      this.viewportHandler();
    },
    handleToolbarIconClick(): void {
      const currentLevel = (this.$router as any).history.current.meta.level;
      if (currentLevel === 0) {
        this.drawer = !this.drawer;
      } else {
        this.$router.push({ name: 'Documents' });
      }
    },
    handleRightArrowToolbarIconClick(): void {
      if (this.$route.name === 'Document') {
        if (this.navigation && (this.navigation as Navigation).next) {
          this.$router.push({
            name: 'Document',
            params: { documentId: (this.navigation as Navigation).next.toString() }
          });
        }
      }
    },
    handleLeftArrowToolbarIconClick(): void {
      if (this.navigation && (this.navigation as Navigation).previous) {
        this.$router.push({
          name: 'Document',
          params: { documentId: (this.navigation as Navigation).previous.toString() }
        });
      }
    },

    handleForwardNavigationClick(): void {
      if (this.$route.name === 'Picture') {
        this.$router.push({
          name: 'Document',
          params: { documentId: this.$route.params.documentId }
        });
      }
    },

    showRefreshUI(e: any): void {
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },

    refreshApp(): void {
      this.snackWithButtons = false;
      if (this.registration !== null) {
        (this.registration as any).waiting.postMessage({
          command: 'skipWaiting',
          options: {}
        });
      }
    },
    handleCloseDrawer(): void {
      this.drawer = false;
    },
    viewportHandler() {
      if (this.pendingUpdate) return;
      this.pendingUpdate = true;
      /*
      requestAnimationFrame(() => {
        this.pendingUpdate = false;
        const viewport = (window as any).visualViewport;
        const appHeight = (this.$refs.app as any).$el.clientHeight;
        const appbarHeight = (this.$refs.appbar as any).$el.clientHeight;
        this.appHeight = appHeight;
        this.viewportHeight = viewport.height;
        this.top = appbarHeight;
        if (this.$route.name === 'Document' && this.userSettings.documentLayout === DocumentLayouts.split) {
          this.appbarTop = appHeight - viewport.height;
          this.top = appHeight + appbarHeight - viewport.height;
        }
      });
      */
    }
  }
});
