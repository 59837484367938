import Product from '@/models/Product/Product';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { State } from './state';

const SET_ALL = (state: State, products: Product[]) => {
  Vue.set(state, 'products', products);
};

const SET_BYPROJECTID = (state: State, payload: { projectId: string; products: Product[] }) => {
  const { projectId, products } = payload;
  state.productsByProject.set('' + projectId, products);
};

const NOTIFY_NEWDATA = (state: State, isNewData: boolean) => {
  Vue.set(state, 'isNewData', isNewData);
};

const SET_ISINSYNC = (state: State, isInSync: boolean) => {
  Vue.set(state, 'isInSync', isInSync);
};

export default {
  SET_ALL,
  NOTIFY_NEWDATA,
  SET_ISINSYNC,
  SET_BYPROJECTID
} as MutationTree<State>;
