import localForageServices from '@/api/localForageService';
import { getSync } from '@/api/Sync/getSync';
import { getUserProfile } from '@/api/UserProfile/getUserProfile';
import { handleDeletedProjects } from '@/helpers/DataCleanerHelper';
import Project from '@/models/Project/Project';
import TeamProject from '@/models/Project/TeamProject';
import StoreNames from '@/models/StoreNames';
import { difference } from 'lodash';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';
import { log } from '@/helpers/ConsoleLogHelper';

const storeName = StoreNames.projects;

const getAll = async (store: ActionContext<State, any>) => {
  const storage = await localForageServices.getStore(storeName);
  const localData = await storage.getItems();
  let localProjects = Object.entries(localData).map((keyValue) => {
    return Project.createFromModel(keyValue[1]);
  });
  getTeamProjects(store);
  const data = await getFromServer(store);
  if (data && data.response) {
    const remoteProjects = data.response
      .filter((p: any) => p.metadata.deleted == 0) // take only projects that are NOT deleted.
      .map((p: any) => {
        return Project.createFromModel(p.object);
      });

    const projectIdsBefore = localProjects.map((p: Project) => p.id);
    const projectIdsNow = remoteProjects.map((p: Project) => p.id);
    const newProjectIds = difference(projectIdsNow, projectIdsBefore);
    handleDeletedProjects(projectIdsNow);

    remoteProjects.forEach((p: Project) => {
      if (newProjectIds.indexOf(p.id) > -1) {
        p.isNew = true;
      }
      storage.setItem(p.id, p);
    });

    localProjects = remoteProjects;
  }

  store.commit('SET_ALL', localProjects);
  store.commit('NOTIFY_NEWDATA', false);
  let selectedProjectId = store.rootState.userProfile.settings.selectedProjectId;
  if (!selectedProjectId) {
    let nameEQ = 'moneyqube_snapper_selectedProjectId' + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) selectedProjectId = c.substring(nameEQ.length, c.length);
    }
  }
  if (!selectedProjectId && localProjects.length > 0) {
    const firstProjectId = localProjects[0].id;
    return store.dispatch('userProfile/setUserProjectId', firstProjectId, {
      root: true
    });
  } else {
    for (let i = 0; i < localProjects.length; i++) {
      if ('' + localProjects[i].id === '' + selectedProjectId) {
        const firstProjectId = localProjects[i].id;
        return store.dispatch('userProfile/setUserProjectId', firstProjectId, {
          root: true
        });
      }
    }
  }
  return;
};

const getTeamProjects = async (store: ActionContext<State, any>) => {
  const data = await getUserProfile(store.rootState.userProfile.userProfile.accessToken, process.env.VUE_APP_API_URL);
  if (data && data.projects) {
    const teamProjects = data.projects.map((p: any) => {
      return TeamProject.createFromModel(p);
    });
    store.commit('SET_TEAM_PROJECTS', teamProjects);
  }
};

const getFromServer = async (store: ActionContext<State, any>) => {
  const timestamp = new Date().getTime();
  if (store.rootGetters['connectivity/isReady']()) {
    return await getSync(
      storeName,
      timestamp,
      0,
      store.rootState.userProfile.userProfile.accessToken,
      process.env.VUE_APP_API_URL
    );
  }
};

const notifyNewData = async (store: ActionContext<State, any>, isNewData: boolean) => {
  store.commit('NOTIFY_NEWDATA', isNewData);
};

const setIsInSync = async (store: ActionContext<State, any>, isinSync: boolean) => {
  store.commit('SET_ISINSYNC', isinSync);
};

export default {
  getAll,
  notifyNewData,
  setIsInSync
} as ActionTree<State, any>;
