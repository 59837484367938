import IProject from '@/models/Project/IProject';
import { log } from '@/helpers/ConsoleLogHelper';

export default class Project {
  public static createFromModel(projectModel: IProject) {
    return new Project(
      projectModel.id,
      projectModel.projectName,
      projectModel.asset,
      projectModel.assetGroup,
      projectModel.shortname
    );
  }

  public readonly id: string;
  public readonly projectName: string;
  public readonly asset: string;
  public readonly assetGroup: string;
  public readonly shortName: string;

  public isNew: boolean;

  public constructor(id: string, projectName: string, asset: string, assetGroup: string, shortName: string) {
    if (!id) {
      throw new Error('Malformed object');
    }
    this.id = '' + id;
    this.projectName = projectName;
    this.asset = asset;
    this.assetGroup = assetGroup;
    this.shortName = shortName;

    this.isNew = false;
  }
}
