import { log } from '@/helpers/ConsoleLogHelper';
import Document from '@/models/Document/Document';
import DocumentFilter from '@/models/Document/DocumentFilter';
import FilterNames from '@/models/FilterNames';
import IFilter from '@/models/IFilter';
import UserSettings from '@/models/User/UserSettings';
import store from '@/store/store';
import moment from 'moment';
import { orderBy } from 'lodash';

export const filterDocuments = (documents: Document[]): Document[] => {
  const userId = (store as any).state.userProfile.userProfile.id;
  const userSettings = (store as any).state.userProfile.settings;

  const dateFilter = userSettings.filters.find((f: IFilter) => f.name === FilterNames.DATE);
  const amountFilter = userSettings.filters.find((f: IFilter) => f.name === FilterNames.AMOUNT);
  const flagFilter = userSettings.filters.find((f: IFilter) => f.name === FilterNames.FLAGS);

  const parameters = flagFilter.parameters as any;
  return orderBy(
    documents.filter((doc: Document) => {
      return (
        filterOutMasterIfDraft(doc) &&
        filterDocument(doc, userSettings, userId) &&
        searchDocument(doc, userSettings) &&
        filterDocumentOnDate(doc, dateFilter) &&
        filterDocumentOnAmount(doc, amountFilter) &&
        filterDocumentOnFlags(doc, flagFilter)
      );
    }),
    'serviceDate',
    parameters.isAsc ? 'asc' : 'desc'
  );
};

const filterOutMasterIfDraft = (document: Document): boolean => {
  if (document.masterId) {
    return true;
  } else if (!document.masterId && store.getters['documents/hasDraft'](document.id)) {
    return false;
  }

  return true;
};

const filterDocument = (document: Document, userSettings: UserSettings, userId: string): boolean => {
  switch (userSettings.selectedFilter) {
    case DocumentFilter.NOTSENT:
      return (
        //userSettings.selectedProjectId === document.projectId &&
        document.createdBy === userId && document.masterId !== ''
      );

    case DocumentFilter.CURRENT:
      return (
        userSettings.selectedProjectId === document.projectId &&
        (!document.masterId || (document.masterId !== '' && document.createdBy === userId))
      );

    case DocumentFilter.RECENT:
      return userSettings.selectedProjectId === document.projectId && document.createdBy === userId;

    case DocumentFilter.ALLSENT:
      return userSettings.selectedProjectId === document.projectId && !document.masterId;
  }
};

const searchDocument = (document: Document, userSettings: UserSettings): boolean => {
  if (!userSettings.queryText) {
    return true;
  }
  try {
    const queries = userSettings.queryText.toLowerCase().split(',');
    const comment = document.comment.toLowerCase();
    const ticket = document.ticket.toLowerCase();
    const supplier = document.supplier.toLowerCase();
    const product = document.product.toLowerCase();
    const chartOfAccount = document.chartOfAccount.toLowerCase();
    const verifiedComment = document.verifiedComment.toLowerCase();
    const redFlagComment = document.redFlagComment.toLowerCase();
    const approvedComment = document.approvedComment.toLowerCase();

    const project = store.getters['projects/byId'](document.projectId);
    const projectName = project.projectName.toLowerCase();
    const asset = project.asset.toLowerCase();
    const group = project.assetGroup.toLowerCase();

    const results = queries.filter((q) => {
      q = q.trim();
      return (
        comment.indexOf(q) > -1 ||
        ticket.indexOf(q) > -1 ||
        supplier.indexOf(q) > -1 ||
        product.indexOf(q) > -1 ||
        verifiedComment.indexOf(q) > -1 ||
        redFlagComment.indexOf(q) > -1 ||
        approvedComment.indexOf(q) > -1 ||
        chartOfAccount.indexOf(q) > -1 ||
        group.indexOf(q) > -1 ||
        asset.indexOf(q) > -1 ||
        projectName.indexOf(q) > -1
      );
    });
    if (results.length === queries.length) {
      return true;
    }
  } catch (err) {
    log(err);
  }
  return false;
};

const filterDocumentOnDate = (document: Document, filter: IFilter): boolean => {
  if (filter.isApplied) {
    const parameters = filter.parameters as any;

    if (parameters.dateFrom === undefined && parameters.dateTo) {
      parameters.dateFrom = parameters.dateTo;
    }
    if (parameters.dateTo === undefined && parameters.dateFrom) {
      parameters.dateTo = parameters.dateFrom;
    }
    const date = moment(document.serviceDate);
    let beginingOfDate = date.startOf('day').valueOf();
    if (beginingOfDate < 100000000000) {
      beginingOfDate = beginingOfDate * 1000;
    }
    log(
      'Filtering beginingOfDate = ' + beginingOfDate + ', from =' + parameters.dateFrom + ', to=' + parameters.dateTo
    );
    if (parameters.dateFrom > 0 && parameters.dateTo > 0) {
      return beginingOfDate >= parameters.dateFrom && beginingOfDate <= parameters.dateTo;
    }
  }
  return true;
};

const filterDocumentOnAmount = (document: Document, filter: IFilter): boolean => {
  if (filter.isApplied) {
    const total = parseFloat(document.total.split(',').join(''));
    const parameters = filter.parameters as any;
    if (parameters.amountFrom === undefined && parameters.amountTo) {
      parameters.amountFrom = parameters.amountTo;
    }
    if (parameters.amountTo === undefined && parameters.amountFrom) {
      parameters.amountTo = parameters.amountFrom;
    }
    log('Filtering total = ' + total + ', amountFrom =' + parameters.amountFrom + ', amountTo=' + parameters.amountTo);
    if (parameters.amountFrom > 0 && parameters.amountTo > 0) {
      return total >= parameters.amountFrom && total <= parameters.amountTo;
    }
  }
  return true;
};

const filterDocumentOnFlags = (document: Document, filter: IFilter): boolean => {
  if (filter.isApplied) {
    const parameters = filter.parameters as any;
    return (
      (!parameters.isRedFlag && !parameters.isVerified && !parameters.isApproved) ||
      (document.redFlag == !!+parameters.isRedFlag &&
        document.verified == !!+parameters.isVerified &&
        document.approved == !!+parameters.isApproved)
    );
  }
  return true;
};
