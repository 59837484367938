import Document from '@/models/Document/Document';
import ChartOfAccount from '@/models/ChartOfAccount/ChartOfAccount';
import { orderBy, pullAllBy, uniqBy, reverse } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';
import { log } from '@/helpers/ConsoleLogHelper';

const all = (state: State) => (): ChartOfAccount[] | null => {
  return pullAllBy(orderBy(uniqBy(state.chartOfAccounts, 'code'), ['name'], ['asc']), [{ title: '' }], 'title');
};
const byProjectId = (state: State, getters: any, rootState: any, rootGetters: any) => (
  projectId: string
): ChartOfAccount[] | null => {
  const coas = state.chartOfAccounts.filter((c: ChartOfAccount) => '' + c.project === '' + projectId);
  return pullAllBy(orderBy(uniqBy(coas, 'title'), ['title'], ['asc']), [{ title: '' }], 'title');
};
const byUsedChartOfAccountFirst = (state: State, getters: any, rootState: any, rootGetters: any) => (
  projectId: string
): ChartOfAccount[] | null => {
  //Get all COAS, sorted COAS and used in project COAS.
  const all: any[] = rootGetters['coas/byProjectId'](projectId) || [];
  const sorted: any[] = all.filter((c: ChartOfAccount) => parseInt(c.code) >= 50000 || c.code === "000");
  const used: any[] = orderBy(uniqBy(rootGetters['documents/byProjectId'](projectId), 'chartOfAccount'), 'chartOfAccount', 'asc');

  //Loop through sorted coas, add used ones first.
  let ret: any[] = [];
  let usedIndexes = [];
  for (let sortedIndex = 0; sortedIndex < sorted.length; sortedIndex++) {
    for (let usedIndex = 0; usedIndex < used.length; usedIndex++) {
      let tmpCoa = String(used[usedIndex].chartOfAccount);
      if (usedIndexes.indexOf(sortedIndex) == -1 && tmpCoa.indexOf(sorted[sortedIndex].code+"-") === 0) {
        ret.push(sorted[sortedIndex]);
        usedIndexes.push(sortedIndex);
      }
    }
  }
  ret.push(new ChartOfAccount('separator', 'separator', 'separator', 'separator'));
  for (let sortedIndex = 0; sortedIndex < sorted.length; sortedIndex++) {
    if (usedIndexes.indexOf(sortedIndex) == -1) {      
      ret.push(sorted[sortedIndex]);
    }
  }
  return ret;
};
const byUsedWhoPaysFirst = (state: State, getters: any, rootState: any, rootGetters: any) => (
  projectId: string
): ChartOfAccount[] | null => {
  //Get all COAS, sorted COAS and used in project COAS.
  const all: any[] = rootGetters['coas/byProjectId'](projectId) || [];
  const sorted: any[] = all.filter((c: ChartOfAccount) => parseInt(c.code) < 50000);
  const used: any[] = orderBy(uniqBy(rootGetters['documents/byProjectId'](projectId), 'whoPays'), 'whoPays', 'asc');

  //Loop through sorted coas, add used ones first.
  let ret: any[] = [];
  let usedIndexes = [];
  for (let sortedIndex = 0; sortedIndex < sorted.length; sortedIndex++) {
    for (let usedIndex = 0; usedIndex < used.length; usedIndex++) {
      let tmpCoa = String(used[usedIndex].whoPays);
      if (usedIndexes.indexOf(sortedIndex) == -1 && tmpCoa.indexOf(sorted[sortedIndex].code+"-") === 0) {
        ret.push(sorted[sortedIndex]);
        usedIndexes.push(sortedIndex);
      }
    }
  }
  ret.push(new ChartOfAccount('separator', 'separator', 'separator', 'separator'));
  for (let sortedIndex = 0; sortedIndex < sorted.length; sortedIndex++) {
    if (usedIndexes.indexOf(sortedIndex) == -1) {      
      ret.push(sorted[sortedIndex]);
    }
  }
  return ret;
};

export default {
  all,
  byProjectId,
  byUsedChartOfAccountFirst,
  byUsedWhoPaysFirst
} as GetterTree<State, any>;
