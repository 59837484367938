import localForageServices from '@/api/localForageService';
import { login } from '@/api/login';
import DocumentFilter from '@/models/Document/DocumentFilter';
import LocalStoreNames from '@/models/LocalStoreNames';
import UserProfile from '@/models/User/UserProfile';
import { ActionContext, ActionTree } from 'vuex';
import { State } from './state';

const storeName = LocalStoreNames.userProfile;

const auth = async (store: ActionContext<State, any>, body: any) => {
  let userProfile;
  if (!body) {
    const jsonUserProfile = localStorage.getItem('userProfile');
    userProfile = jsonUserProfile ? JSON.parse(jsonUserProfile) : null;
  } else {
    const data = await login(body, process.env.VUE_APP_API_URL);
    if (data && data.response) {
      //Clear old user's DB, fetch new data
      //localStorage.clear();
      userProfile = data.response;
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
    }
  }

  if (userProfile) {
    store.commit('SET_ISAUTHED', UserProfile.createFromModel(userProfile));
    store.dispatch('historyItems/programFullServerSync', {}, { root: true });

    const isFullSync = localStorage.getItem('isFullSync');

    if (isFullSync) {
      const selectedFilter = localStorage.getItem('selectedFilter');

      if (selectedFilter) {
        store.commit('SET_SELECTEDFILTER', selectedFilter);
        localStorage.removeItem('isFullSync');
      }
    }
  }
  return;
};

const logout = async (store: ActionContext<State, any>) => {
  store.commit('SET_ISAUTHED', null);
  localStorage.removeItem('userProfile');
  store.dispatch('historyItems/cancelProgramedFullServerSync', {}, { root: true });
};

const setUserProjectId = async (store: ActionContext<State, any>, selectedProjectId: string) => {
  const storage = await localForageServices.getStore(storeName);
  store.commit('SET_USER_PROJECT', selectedProjectId);
  await storage.setItem('selectedProjectId', selectedProjectId);
  document.cookie = 'moneyqube_snapper_selectedProjectId=' + (selectedProjectId || '') + '; path=/';
};

const setSelectedFilter = (store: ActionContext<State, any>, selectedFilter: DocumentFilter) => {
  store.commit('SET_SELECTEDFILTER', selectedFilter);
  localStorage.setItem('selectedFilter', selectedFilter);
};

const setQueryText = (store: ActionContext<State, any>, queryText: string) => {
  store.commit('SET_QUERYTEXT', queryText);
  // localStorage.setItem('queryText', queryText);
};

const setDateFrom = (store: ActionContext<State, any>, dateFrom: number) => {
  store.commit('SET_DATEFROM', dateFrom);
};

const setDateTo = (store: ActionContext<State, any>, dateTo: number) => {
  store.commit('SET_DATETO', dateTo);
};

const setAmountFrom = (store: ActionContext<State, any>, from: number) => {
  store.commit('SET_AMOUNTFROM', from);
};

const setAmountTo = (store: ActionContext<State, any>, to: number) => {
  store.commit('SET_AMOUNTTO', to);
};

const setRedFlag = (store: ActionContext<State, any>, isRedFlag: boolean) => {
  store.commit('SET_REDFLAG', isRedFlag);
};

const setVerified = (store: ActionContext<State, any>, isVerified: boolean) => {
  store.commit('SET_VERIFIED', isVerified);
};

const setApproved = (store: ActionContext<State, any>, isApproved: boolean) => {
  store.commit('SET_APPROVED', isApproved);
};

const setAsc = (store: ActionContext<State, any>, isAsc: boolean) => {
  store.commit('SET_ASC', isAsc);
};

const clearFilters = (store: ActionContext<State, any>) => {
  store.commit('CLEAR_FILTERS');
};

const applyFilters = (store: ActionContext<State, any>) => {
  store.commit('APPLY_FILTERS');
};

const cancelFilters = (store: ActionContext<State, any>) => {
  store.commit('CANCEL_FILTERS');
};

const toggleDocumentPicturePanel = (store: ActionContext<State, any>) => {
  store.commit('TOGGLE_DOCUMENT_PICTURE_PANEL');
};

const closeDocumentPicturePanel = (store: ActionContext<State, any>) => {
  store.commit('CLOSE_DOCUMENT_PICTURE_PANEL');
};

export default {
  auth,
  logout,
  setUserProjectId,
  setSelectedFilter,
  setQueryText,
  setDateFrom,
  setDateTo,
  setAmountFrom,
  setAmountTo,
  clearFilters,
  applyFilters,
  cancelFilters,
  setRedFlag,
  setVerified,
  setApproved,
  setAsc,
  toggleDocumentPicturePanel,
  closeDocumentPicturePanel
} as ActionTree<State, any>;
