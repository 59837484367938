import { filterDocuments } from '@/helpers/DocumentFilterHelper';
import Document from '@/models/Document/Document';
import { Navigation } from '@/models/Document/DocumentNavigation';
import DocumentVersion from '@/models/Document/DocumentVersion';
import { DocumentVersionNavigation } from '@/models/Document/DocumentVersionNavigation';
import Project from '@/models/Project/Project';
import { findIndex, orderBy } from 'lodash';
import { GetterTree } from 'vuex';
import { State } from './state';
import { log } from '@/helpers/ConsoleLogHelper';

const byId = (state: State) => (id: string): Document | undefined => {
  return state.documents.find((c) => c.id === id);
};

const byProjectId = (state: State) => (projectId: string): Document[] => {
  const documents = state.documents.filter((d: Document) => d.projectId == projectId);
  return orderBy(documents, 'date', 'desc');
};

const latestByProjectId = (state: State) => (projectId: string): Document | undefined => {
  const getByProjectId = byProjectId(state);
  const sorted = getByProjectId(projectId);
  return sorted[0];
};

const notVerified = (state: State) => (): Document[] => {
  const documents = state.documents.filter((d: Document) => d.verified === undefined);
  return orderBy(documents, 'date', 'desc');
};

const latestNotSentByProjectId = (state: State) => (projectId: string): Document | undefined => {
  const documents = state.documents.filter((d: Document) => d.projectId === projectId && !d.masterId);
  const sorted = orderBy(documents, 'date', 'desc');
  return sorted[0];
};

const verified = (state: State) => (): Document[] => {
  return state.documents.filter((d: Document) => d.verified === true);
};

const isToSend = (state: State) => (): Document[] => {
  return state.documents.filter((d: Document) => d.toSend === true);
};

const toSendCount = (state: State) => (): number => {
  const documents = state.documents.filter((d: Document) => d.toSend === true);
  return documents.length;
};

const navigation = (state: State, getters: any, rootState: any, rootGetters: any) => (
  currentId: string
): Navigation => {
  const orderedProjects = rootGetters['projects/hasDocuments']();
  const sortByProjectName = (d: Document) => {
    return orderedProjects.findIndex((p: Project) => p.id === d.projectId);
  };

  const orderedDocuments = orderBy(state.documents, [sortByProjectName, 'date'], ['asc', 'desc']);
  const filteredDocumentIds = filterDocuments(orderedDocuments).map((d) => d.id);
  const currentIndex = filteredDocumentIds.indexOf(currentId);

  const previous = filteredDocumentIds[currentIndex - 1];
  const next = filteredDocumentIds[currentIndex + 1];
  return { previous, next };
};

const versionNav = (state: State) => (id: string, timestamp: number): DocumentVersionNavigation | undefined => {
  log('versionNav.begin');
  let navigation: any[] = [];

  let document = byId(state)(id);
  if (!document) {
    return undefined;
  }
  navigation.push({ id: id, timestamp: document.date });

  const getNavigations = (doc: Document) => {
    if (!doc.history) {
      return [];
    }
    return doc.history.map((version: DocumentVersion) => {
      return {
        id: version.document.id,
        timestamp: version.timestamp
      };
    });
  };

  if (document.masterId && document.id !== document.masterId) {
    //We've got a draft, versions resides in master.
    const master = byId(state)(document.masterId);
    if (master) {
      navigation.push({ id: master.id, timestamp: master.date });
      if (master.history) {
        navigation.push(...getNavigations(master));
      }
    }
  } else {
    //We've got a master, don't forget to add draft.
    navigation.push(...getNavigations(document));
    const draft = firstDraft(state)(document.id);
    if (draft && draft.id !== document.id) {
      navigation.push({ id: draft.id, timestamp: draft.date });
    }
  }

  const allSortedVersion = orderBy(navigation, 'timestamp', 'asc');

  let currentIndex = 0;
  if (timestamp) {
    currentIndex = findIndex(allSortedVersion, (n: any) => n.id === id && n.timestamp === timestamp);
  } else {
    const documentDate = document.date;
    currentIndex = findIndex(allSortedVersion, (n: any) => n.id === id && n.timestamp === documentDate);
  }

  const previous = allSortedVersion[currentIndex - 1];
  const next = allSortedVersion[currentIndex + 1];
  log('versionNav.end');
  return { previous, next };
};

const version = (state: State) => (id: string, timestamp: number): Document | undefined => {
  log('version.begin');
  const document = byId(state)(id);
  if (!document) {
    log('version.end2');
    return undefined;
  }
  if (document.date === timestamp) {
    log('version.end3');
    return document;
  }

  if (document.history) {
    const version = document.history.find((h: DocumentVersion) => h.timestamp === timestamp);
    log('version.end4');
    return version ? version.document : document;
  }
  log('version.end');
  return document;
};

const hasDraft = (state: State) => (id: string): boolean => {
  firstDraft(state)(id);
  return firstDraft(state)(id) ? true : false;
};

const firstDraft = (state: State) => (id: string): Document | undefined => {
  if (!id) {
    return undefined;
  }
  return state.documents.find((d: Document) => d.masterId === id);
};

const isLatestVersion = (state: State) => (id: string, timestamp: number): boolean => {
  log('isLatestVersion.begin');
  const nav = versionNav(state)(id, timestamp);
  if (!nav) {
    log('isLatestVersion.end');
    return false;
  }
  let document = byId(state)(id);
  let isSameDate =
    nav.next &&
    document &&
    document.date &&
    (Number('' + document.date + '000') >= Number('' + nav.next.timestamp) ||
      Number('' + document.date) >= Number('' + nav.next.timestamp));
  log('isLatestVersion.end2');
  return !nav.next || isSameDate ? true : false;
};

export default {
  byId,
  byProjectId,
  latestByProjectId,
  latestNotSentByProjectId,
  notVerified,
  verified,
  isToSend,
  toSendCount,
  navigation,
  version,
  versionNav,
  hasDraft,
  firstDraft,
  isLatestVersion
} as GetterTree<State, any>;
