import { default as DocumentVersion } from './DocumentVersion';
import IDraftable from './IDraftable';
export default class Document implements IDraftable {
  public readonly masterId: string;

  public readonly id: string;
  public readonly projectId: string;
  public readonly createdBy: string;
  public readonly userName: string;
  public readonly toSend: boolean;
  public readonly date: number;
  public readonly comment: string;
  public readonly ticket: string;
  public readonly supplier: string;
  public readonly product: string;
  public readonly chartOfAccount: string;
  public readonly units: string;
  public readonly perUnit: string;
  public readonly tax: string;
  public readonly tip: string;
  public readonly verified: boolean | undefined;
  public readonly verifiedComment: string;
  public readonly redFlag: boolean | undefined;
  public readonly redFlagComment: string;
  public readonly measurement: string;

  public readonly serviceDate: number;
  public readonly history: DocumentVersion[];
  public readonly pictureIds: string[];
  public readonly approved: boolean | undefined;
  public readonly approvedComment: string;
  public readonly total: string;
  public readonly amount: string;
  public readonly deposit: string;
  public readonly whoPays: string;
  public readonly invoiceNumber: string;
  public readonly poNumber: string;

  public isNew: boolean;

  public constructor(
    id: string,
    projectId: string,
    createdBy: string,
    userName: string,
    masterId?: string,
    toSend?: boolean,
    date?: number,
    comment?: string,
    ticket?: string,
    supplier?: string,
    product?: string,
    chartOfAccount?: string,
    units?: string,
    perUnit?: string,
    verified?: boolean,
    verifiedComment?: string,
    redFlag?: boolean,
    redFlagComment?: string,
    isNew?: boolean,
    measurement?: string,
    serviceDate?: number,
    history?: DocumentVersion[],
    pictureIds?: string[],
    approved?: boolean | undefined,
    approvedComment?: string,
    tax?: string,
    tip?: string,
    total?: string,
    amount?: string,
    deposit?: string,
    whoPays?: string,
    invoiceNumber?: string,
    poNumber?: string
  ) {
    if (!id || !projectId) {
      throw new Error('Malformed document');
    }
    this.id = id;
    this.projectId = projectId;
    this.createdBy = createdBy;
    this.userName = userName;
    this.masterId = masterId || '';
    this.toSend = toSend ? !!+toSend : false;

    const currentDate = Date.now();
    date ? (this.date = date) : (this.date = currentDate);
    this.comment = comment || '';
    this.ticket = ticket || '';
    this.supplier = supplier || '';
    this.product = product || '';
    this.chartOfAccount = chartOfAccount || '';
    this.units = units || '';
    this.perUnit = perUnit || '';
    this.verified = verified ? !!+verified : undefined;
    this.verifiedComment = verifiedComment || '';
    this.redFlag = redFlag ? !!+redFlag : undefined;
    this.redFlagComment = redFlagComment || '';
    this.isNew = isNew || false;
    this.measurement = measurement || '';

    serviceDate ? (this.serviceDate = serviceDate) : (this.serviceDate = currentDate);

    this.history = history || [];
    this.pictureIds = pictureIds ? pictureIds : [];
    this.approved = approved ? !!+approved : undefined;
    this.approvedComment = approvedComment || '';

    this.tax = tax || '';
    this.tip = tip || '';
    this.total = total || '';
    this.amount = amount || '';
    this.deposit = deposit || '';
    this.whoPays = whoPays || '';
    this.invoiceNumber = invoiceNumber || '';
    this.poNumber = poNumber || '';
  }
}
